.container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.section {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.heading {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.carContainer {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.carImage {
    width: 200px;
    height: auto;
    margin-right: 20px;
}

.carDetails {
    flex: 1;
}

.carDetails h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.carDetails p {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
}


/* Styles for credit card input fields */

.summaryContainer {
    display: flex;
    flex-direction: column;
}

.summaryText {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
}

.confirmButton {
    background-color: #4caf50;
    color: #fff;
    padding: 15px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
    align-self: flex-end;
}

.confirmButton:hover {
    background-color: #45a049;
}
