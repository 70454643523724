.page_container {
  width: 100%;
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.car_container {
  width: 80%;
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  width: 100%;
  height: 300px;
  overflow: hidden;
  border-bottom: 1px solid #ddd;
}

.car_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.car_details {
  padding: 20px;
  text-align: center;
}

.car_title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 10px;
  color: #333;
}

.car_description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

.price {
  font-size: 1.3rem;
  color: #dc143c;
  margin-bottom: 15px;
}

.reservation_btn {
  padding: 12px 30px;
  border: none;
  color: #fff;
  background-color: #3498db;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  border-radius: 5px;
  font-size: 1rem;
}

.reservation_btn:hover {
  background-color: #3498dbab;
}

.back_btn {
  font-size: 1.8rem;
}

.back_btn:hover {
  cursor: pointer;
}