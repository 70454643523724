.creditCardContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.creditCardLine {
    display: flex;
    gap: 20px;
}

.creditCardField {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.expirationDateContainer {
    display: flex;
    gap: 10px;
    align-items: center;
}

.selectContainer {
    position: relative;
}

.selectContainer::after {
    content: '\25BC';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
}

.creditCardContainer select {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    appearance: none;
    background-color: white;
}

label {
    font-size: 12px;
    color: #555;
}

input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}