/* reserveCar.module.css */

.container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.carContainer {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.carImage {
    width: 200px;
    height: auto;
    margin-right: 20px;
}

.carDetails {
    flex: 1;
}

.carDetails h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.carDetails p {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
}

.label {
    display: block;
    font-size: 18px;
    margin-bottom: 8px;
}

.input {
    width: 100%;
    padding: 12px;
    margin-bottom: 16px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.datePicker {
    width: 100%;
    padding: 12px;
    margin-bottom: 16px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submitButton {
    background-color: #3498db;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
}

.submitButton:hover {
    background-color: #2980b9;
}