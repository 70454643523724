/* Vullkanizer.module.css */
.container-fluid {
    padding: 0;
}

.header {
    /* position: relativSe; */
    padding: 80px 0;
    width: auto;
    background-color: #292b2c;
    /* Dark background color */
    border-radius: 10px;
    margin-bottom: 40px;
    text-align: center;
}

.overlay {
    /* position: absolute; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    /* z-index: 1; */
    text-align: center;
}

.title {
    font-size: 3em;
    color: #dc3545;
    /* Red text color */
    margin-bottom: 20px;
}

.subtitle {
    font-size: 1.5em;
    color: #6c757d;
}

.vullkanizerContent {
    padding: 40px 0;
}

.photosSection {
    margin-top: 40px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.photoItem {
    width: 45%;
    margin-bottom: 40px;
    position: relative;
}

.brandPhoto {
    width: 100%;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }
}

.servicesSection {
    margin-top: 60px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.service {
    width: 30%;
    margin-bottom: 60px;
    position: relative;
    text-align: center;

    h2 {
        font-size: 2em;
        margin-bottom: 10px;
        color: #007bff;
        /* Blue heading color */
    }

    p {
        font-size: 1.1em;
        margin-bottom: 20px;
    }

    .servicePhoto {
        width: 100%;
        border-radius: 10px;
        max-height: 200px;
        object-fit: cover;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.additionalSection {
    margin-top: 60px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.additionalItem {
    width: 45%;
    margin-bottom: 40px;
    position: relative;
}