/* Footer.module.css */
.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to left, #3498db, #2c3e50);
    color: white;
    padding: 30px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 16px;
}

.location {
    text-align: left;
    flex-grow: 1;
}

.location a {
    color: #e74c3c;
    /* Highlight color for the link */
    font-weight: bold;
    text-decoration: underline;
}

.socialIcons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.footer a {
    text-decoration: none;
    color: white;
    font-size: 20px;
    margin: 0 15px;
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s ease;
}

.footer a:hover {
    color: #dc3545;
    border-color: #3498db;
}