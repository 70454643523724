.page_container {
    width: 100%;
    padding-top: 60px;
}

.search_container {
    width: 80%;
    display: flex;
    align-items: center; /* Center items horizontally */
    gap: 16px;
    margin-bottom: 16px;
    margin: 0 auto; /* Center the container */
}

.search_bar {
    flex: 1;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.dropdown {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.search_button {
    background-color: #3498db;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.search_button:hover {
    background-color: #2980b9;
}

a {
    text-decoration: none;
    color: #000;
}

.main_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
}

.car_container {
    width: 100%;
    background-color: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.car_container:hover {
    transform: scale(1.05);
}

.image {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-bottom: 1px solid #ddd;
    border-radius: 10px 10px 0 0;
}

.car_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}

.car_details {
    padding: 20px;
    text-align: center;
}

.car_name {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 10px;
    color: #333;
}

.price {
    font-size: 1.3rem;
    color: var(--primary-color);
    margin-bottom: 8px;
}

.btn {
    display: inline-block;
    margin-top: 15px;
    padding: 12px 30px;
    border: none;
    color: white;
    background-color: #3498db;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    border-radius: 5px;
}

.btn:hover {
    background-color: #3498dbab;
}



@media (min-width: 425px) {
    .car_container {
        width: 85%;
    }
}

@media (min-width: 480px) {
    .car_container {
        width: 80%;
    }
}

@media (min-width: 768px) {
    .main_container {
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        padding: 0 2rem 6.7rem 2rem;
    }

    .car_container {
        width: 100%;
    }

    .arrow_btn {
        top: 92.5%;
    }
}

@media (min-width: 1024px) {
    .main_container {
        padding: 2rem 5.5rem;
        margin-top: 0;
    }

    .main_banner {
        height: 250px;
    }

    .banner_img {
        object-position: top;
    }

    .main_banner-text {
        top: 35%;
        gap: 20px;
    }

    .search {
        max-width: 400px;
    }

    .arrow_btn {
        top: 50%;
    }
}

@media (min-width: 1200px) {
    .main_container {
        padding: 2rem 8rem;
        gap: 2.5rem;
    }

    .car_container {
        height: 315px;
    }

    .image {
        width: 300px;
        height: 190px;
        margin-top: 5px;
    }

    .car_details {
        padding: 0 3rem;
    }
}

@media (min-width: 1400px) {
    .main_container {
        padding: 2rem 10rem;
        gap: 3rem;
    }

    .main_banner {
        height: 270px;
    }

    .banner_img {
        object-position: center;
    }

    .car_container {
        height: 345px;
    }

    .image {
        height: 200px;
        margin-top: 15px;
    }

    .car_details {
        padding: 0 5rem;
    }

    .btn {
        padding: 12px 30px;
        font-size: 1rem;
    }
}
