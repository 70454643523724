/* AboutUs.module.css */

.container {
  background-color: #f8f9fa;
  padding: 20px;
}

.section {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}

.hero {
  text-align: center;
  background-color: #007bff;
  color: #fff;
  padding: 50px 0;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #fff;
}

.subtitle {
  font-size: 1.2rem;
  color: #fff;
}

.subSection {
  text-align: center;
}

.sectionTitle {
  font-size: 2.5rem;
  color: #007bff;
  /* Blue section title color */
  margin-bottom: 20px;
}

.section-title {
  font-size: 2.5rem;
  color: #007bff;
  /* Blue section title color */
  margin-bottom: 20px;
}

.section-subtitle {
  font-size: 1.2rem;
  color: #666;
}

.sectionSubtitle {
  font-size: 1.2rem;
  color: #666;
}

.servicesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.serviceCard {
  flex: 1;
  max-width: 300px;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  /* White background color */
}

.whyChooseContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.whyChooseCard {
  flex: 1;
  max-width: 300px;
  margin: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  /* Light gray background color */
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contactInfo {
  margin-top: 20px;
  font-size: 1.2rem;
}

/* aboutUs.module.css */

.contactSection {
  background-color: #f8f9fa;
  /* Light gray background color */
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  /* Add a subtle box shadow */
}

.contactInfoCard {
  background-color: #fff;
  /* White background color */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a box shadow to the cards */
  margin: 20px;
  /* Adjust margin for spacing between cards */
}

.contactInfoCard p {
  margin: 10px 0;
  /* Adjust spacing between contact info items */
}

.mapContainer {
  width: 100%;
  height: 400px;
  /* Adjust the height according to your design */
  margin-top: 20px;
}

.map {
  width: 100%;
  height: 100%;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .contactInfoCard {
    width: 100%;
    /* Make cards full width on smaller screens */
  }
}