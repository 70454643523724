.servisContainer {
    padding: 0;
}

.header {
    padding: 40px 0;
    /* Adjust padding for better readability on small screens */
    height: auto;
    /* Allow header height to adjust based on content */
    background-image: url("../Photos/auto_service.png");
    background-size: cover;
    background-position: center;
    border-radius: 0;
    margin-bottom: 0;
    text-align: center;
    color: #fff;
}

.overlay {
    background: #0F5C97;
    padding: 10px;
    /* Reduce overlay padding on small screens */
    text-align: center;
}

.servisContent {
    padding: 20px 0;
    /* Adjust padding for better readability on small screens */
}

.dynamicSection {
    display: flex;
    flex-direction: column;
    /* Stack sections vertically on small screens */
    margin-bottom: 20px;
    text-align: center;
    /* Center content on small screens */
}

.dynamicText {
    padding: 20px;
    /* Add padding for better readability on small screens */
    text-align: center;
    /* Center text on small screens */
}

.title {
    font-size: 2em;
    /* Reduce font size for better readability on small screens */
    color: #fff;
    margin-bottom: 10px;
}

.subtitle {
    font-size: 1.2em;
    /* Adjust font size for better readability on small screens */
    color: #f1f1f1;
}

.dynamicImage {
    width: 100%;
    /* Make images take full width on small screens */
    border-radius: 10px;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }
}

/* Media query for screens larger than 768px wide */
@media (min-width: 769px) {
    .header {
        padding: 80px 0;
        /* Restore header padding for larger screens */
        height: 320px;
        /* Restore fixed header height for larger screens */
    }

    .overlay {
        padding: 20px;
        /* Restore overlay padding for larger screens */
    }

    .servisContent {
        padding: 40px 0;
        /* Restore padding for larger screens */
    }

    .dynamicSection {
        flex-direction: row;
        /* Align sections side by side for larger screens */
        margin-bottom: 60px;
    }

    .dynamicText {
        flex: 1;
        padding: 0 20px;
        /* Restore padding for larger screens */
        text-align: left;
        /* Reset text alignment for larger screens */
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* Center text vertically */
    }

    .title {
        font-size: 3em;
        /* Restore font size for larger screens */
    }

    .subtitle {
        font-size: 1.5em;
        /* Restore font size for larger screens */
    }

    .dynamicImage {
        width: 45%;
    }
}