/* CarCleaningPage.module.css */
.carCleaningContainer {
    padding: 0;
}

.header {
    position: relative;
    padding: 80px 0;
    width: auto;
    height: 320px;
    background-image: url("../Photos/auto_detailing.png");
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    margin-bottom: 0;
    text-align: center;
    color: #fff;
}

.overlay {
    /* position: absolute; */
    /* top: 50%;
    left: 50%; */
    color: black;
    /* transform: translate(-50%, -50%); */
    /* z-index: 1; */
    text-align: center;
}

.title {
    font-size: 3em;
    color: black;
    margin-bottom: 20px;
}

.subtitle {
    font-size: 1.5em;
    color: black;
}

.carCleaningContent {
    padding: 40px 0;
}

.dynamicSection {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 60px;
}

.dynamicText {
    flex: 1;
    padding: 0 20px;
    text-align: left;
    color: #333;
    /* Adjusted text color for better visibility */
}

.dynamicImage {
    width: 50%;
    border-radius: 10px;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    /* Added a subtle shadow */

    &:hover {
        transform: scale(1.1);
    }
}