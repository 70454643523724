* {
    box-sizing: border-box;
}

body {
    background-color: #fff;
    margin: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.nav {
    background-color: #2c3e50;
    /* Dark Blue-Grey */
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    /* Adding a subtle box-shadow */
}

.site-title {
    font-size: 2rem;
    color: #ecf0f1;
    /* Light Grey */
    text-decoration: none;
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
}

.nav a {
    color: #ecf0f1;
    /* Light Grey */
    text-decoration: none;
    padding: 0.5rem;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.nav a:hover {
    background-color: #34495e;
    /* Dark Blue-Grey on hover */
}

.nav li.active {
    background-color: #3498db;
    /* Light Blue */
}

.nav li.active a {
    background-color: #2980b9;
    /* Blue for the active link */
}

.menu-icon {
    display: none;
    /* Initially hide the menu icon for larger screens */
    cursor: pointer;
}

.menu-icon span {
    display: block;
    width: 30px;
    height: 3px;
    background-color: white;
    margin: 6px 0;
}


@media (max-width: 768px) {
    .nav {
        width: 100%;
    }
    .nav ul {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 0;
        /* width: auto; */
        background-color: #2c3e50;
        padding: 1rem;
        /* Add padding for better spacing */
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        z-index: 1;
        /* Ensure the menu is above other elements */
    }

    .nav ul.open {
        display: flex;
    }

    .nav a {
        padding: 1rem;
        /* Increase padding for better touch interaction */
    }

    .menu-icon {
        display: block;
        padding: 1rem;
        /* Add padding for better touch interaction */
    }

    .nav li.active {
        background-color: #3498db;
    }

    .nav li.active a {
        background-color: #2980b9;
    }
}